<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isEditPackageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="$emit('clicked',false);"
    @change="(val) => $emit('two')"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Plan
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- BODY -->
      <validation-observer
        ref="simpleRules"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
          v-if="selected2"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Display Name"
            rules="required"
          >
            <b-form-group
              label="Plan Code"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="selected2.label"
                autofocus

                trim
                placeholder="Plan Code"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="type"
            rules="required"
          >
            <b-form-group
              label="Type"
              label-for="Type"

            >
              <v-select
                v-model="selected2.type"
                :options="typeOptions"
                :clearable="false"
                input-id="type"
                :reduce="val => val.value"
              />
              <b-form-invalid-feedback >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="amount"
            rules="required|between:0,1000000"
          >
            <b-form-group
              label="Amount"
              label-for="Amount"
            >
              <b-form-input
                id="Amount"
                v-model="selected2.amount"

                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="period"
            rules="required|between:0,1000"
          >
            <b-form-group
              label="Period Quantity"
              label-for="quantity Quantity"
            >
              <b-form-input
                id="period quantity"
                v-model="selected2.period_quantity"
                class="text-uppercase"

                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider
            #default="validationContext"
            name="unit"
            rules="required"
          >
            <b-form-group
              label="Unit"
              label-for="unit"

            >
              <v-select
                v-model="selected2.period_unit"
                :options="unitOptions"
                :clearable="false"
                input-id="type"
                :reduce="val => val.value"
              />
              <b-form-invalid-feedback >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>




          <validation-provider
            #default="validationContext"
            name="duration"
            rules="required|between:1,180"
          >
            <b-form-group
              label="Class Credit"
              label-for="Class Credit"
            >
              <b-form-input
                id="Class Credit"
                v-model="selected2.class_credit"

                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
<!--
          <validation-provider
            #default="validationContext"
            name="class_credit_unit"
            rules="required"
          >
            <b-form-group
              label="Class Credit Unit"
              label-for="Class Credit Unit"

            >
              <v-select
                v-model="selected2.class_credit_unit"
                :options="class_credit_options"
                :clearable="false"
                input-id="type"
                :reduce="val => val.value"
              />
              <b-form-invalid-feedback >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
-->
          <validation-provider
            #default="validationContext"
            name="dues_charge_after_expire"
            rules="required"
          >
            <b-form-group
              label="Charge After Expire (For Dues)"
              label-for="Charge After Expire (For Dues)"

            >
              <v-select
                v-model="selected2.dues_charge_after_expire"
                :options="YNOptions"
                :clearable="false"
                input-id="type"
                :reduce="val => val.value"
              />
              <b-form-invalid-feedback >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="dues_charge_after_expire"
            rules="required"
          >
            <b-form-group
              label="Commission"
              label-for="Commission"

            >
              <v-select
                v-model="selected2.has_commission"
                :options="YNOptions"
                :clearable="false"
                input-id="type"
                :reduce="val => val.value"
              />
              <b-form-invalid-feedback >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="status"
            rules="required"
          >
            <b-form-group
              label="Status"
              label-for="Status"

            >
              <v-select
                v-model="selected2.status"
                :options="statusOptions"
                :clearable="false"
                input-id="type"
                :reduce="val => val.value"
              />
              <b-form-invalid-feedback >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2 mb-5">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              :disabled="processing"
              @click="submit_now()"
            >
              Edit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="ml-2"
              variant="outline-danger"
              @click="delete_now()"
            >
              Delete
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import countries from '@/@fake-db/data/other/countries'

export default {
  components: {
    ToastificationContent,
    flatPickr,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditPackageSidebarActive',
    event: 'update:is-edit-Package-sidebar-active',
  },
  props: {
    isEditPackageSidebarActive: {
      type: Boolean,
      required: true,
    },
    selected: {
      required: true,
    }
  },
  watch: {
    selected: {
      handler(newValue, oldValue) {
        this.selected2 = newValue
      },
    },
  },
  data() {
    return {
      processing:false,
      selected2 : null,
      class_credit_options: [
        { label: 'TOTAL', value: 'TOTAL' },
        { label: 'AVERAGE', value: 'AVERAGE' },
      ],
      typeOptions: [
        { label: 'TERMS', value: 'TERMS' },
        { label: 'DUES', value: 'DUES' },
      ],
      YNOptions: [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' },
      ],
      statusOptions: [
        { label: 'ACTIVE', value: "ACTIVE" },
        { label: 'DISABLED', value: "DISABLED" },
      ],
      unitOptions: [
        { label: 'DAYS', value: 'DAYS' },
        { label: 'MONTHS', value: 'MONTHS' },
      ],
      required,
      alphaNum,
      email,
      countries,
      blankselected: {
        display_name: '',
        username: '',
        password: '',
        role: 'admin',
        status: '',
      },
    }
  },
  methods: {
    submit_now() {
      console.log(this.$refs.simpleRules.validate())

        //  this.processing = true
          this.$http.post(process.env.VUE_APP_API_BASE+'/plan/edit/', this.selected2)
            .then(res => {
              if (res.data.result === true) {
                this.processing = false
                this.$emit('clicked', false)
                this.$refs.simpleRules.reset()
              } else {
                this.processing = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Error',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: res.data.message,
                  },
                })
              }
            })

    },
    delete_now() {
        //  this.processing = true
          this.$http.post(process.env.VUE_APP_API_BASE+'/plan/delete/', this.selected2)
            .then(res => {
              if (res.data.result === true) {
                this.$emit('clicked', false)
                this.$refs.simpleRules.reset()
              } else {
                this.processing = false
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Error',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: res.data.message,
                  },
                })
              }
            })


    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
